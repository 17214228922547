'use client'

import type Natural from '@repo/types/number/Natural'
import type Range from '@repo/types/number/Range'
import type Some from '@repo/types/array/Some'
import { type Dispatch, type SetStateAction, useEffect, useState, useMemo, type ReactNode } from 'react'
import { motion, LayoutGroup, type Variants } from 'framer-motion'
import { cn } from '@repo/ui/lib/utils'
import { getColorClass } from '@repo/ui/lib/color'

export interface AnimatedTextBadgeProps {
    items: Some<{
        text: ReactNode
        color?: string
    }>

    index?: Natural

    interval?: Natural
    transitionDuration?: Natural
    transitionEase?: string

    className?: Some<NonNullable<Parameters<typeof cn>>[number]>
}

const variants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
} as const satisfies Variants

export default function AnimatedTextBadge({
    items,
    index = 0,

    interval = 1_500,
    transitionDuration = 0.4,
    transitionEase = 'easeOut',

    className,
}: Omit<AnimatedTextBadgeProps, 'index'> & {
    index?: typeof items extends Array<any>
        ? (typeof items)['length'] extends Range
            ? Range<0, (typeof items)['length']>
            : Natural
        : Natural
}) {
    let setIndex: Dispatch<SetStateAction<typeof index>>
    ;[index, setIndex] = useState(index ?? 0)

    const array = useMemo(() => {
        if (items === undefined) return []
        if (!Array.isArray(items)) return [items]
        return items
    }, [items])

    const text = useMemo(() => {
        const text = array[index]?.text
        if (text === undefined || text === null) return undefined
        if (typeof text === 'string') return text
        if (typeof text === 'object') return text
        return text.toString()
    }, [array, index])

    const key = useMemo(() => {
        if (typeof text === 'string') return text
        if (text === undefined) return `animated_text_badge_text_${index}`
        if (Array.isArray(text)) return `animated_text_badge_text${index}`
        if ('key' in text && typeof text.key === 'string') return text.key
        return `animated_text_badge_text${index}`
    }, [text, index])

    const backgroundColor = useMemo(() => {
        const color = array[index]?.color
        if (color === undefined) return 'foreground'
        return getColorClass(color) ?? color
    }, [array, index])

    const color = useMemo(() => {
        const color = array[index]?.color
        if (color === undefined) return 'background'
        return 'white'
    }, [array, index])

    useEffect(() => {
        const intervalId = setInterval(() => setIndex(prev => (prev + 1) % array.length), interval)

        return () => clearInterval(intervalId)
    }, [array, setIndex, interval])

    return (
        <LayoutGroup>
            <motion.div className='relative inline-flex items-center' layout>
                <motion.div
                    layout
                    transition={{
                        layout: {
                            duration: transitionDuration,
                            ease: transitionEase,
                        },

                        backgroundColor: {
                            duration: transitionDuration,
                            ease: transitionEase,
                        },
                    }}
                    className={cn(`text-${color}`, `bg-${backgroundColor}`, className)}
                    aria-live='polite'
                    aria-atomic='true'
                >
                    <motion.span variants={variants} initial='enter' animate='center' exit='exit' key={key}>
                        {array[index]?.text}
                    </motion.span>
                </motion.div>
            </motion.div>
        </LayoutGroup>
    )
}
